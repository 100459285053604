<template>
  <div>
    <div
      class="SmtpConfigEditForm__switch"
    >
      <h3>{{ $t('service.Smtp') }}</h3>
      <AppSwitch
        :input-value="useSmtp"
        class="ma-0 pa-0 ml-auto"
        name="smtp-switch"
        hide-details
        @change="switchSmtp"
      />
    </div>

    <v-form
      ref="form"
      class="SmtpConfigEditForm"
      :disabled="!useSmtp"
    >
      <fieldset class="SmtpConfigEditForm__fieldset">
        <AppTextField
          v-model="form.host"
          class="SmtpConfigEditForm__field"
          filled
          :label="$t('service.Host')"
          placeholder="smtp.server.com"
          name="smtp-host"
          :error-messages="getErrors('host')"
        />

        <AppCombobox
          v-model.number="form.port"
          class="SmtpConfigEditForm__field"
          filled
          :label="$t('service.Port')"
          placeholder="25"
          type="number"
          name="smtp-port"
          :error-messages="getErrors('port')"
        />

        <AppTextField
          v-model="form.username"
          class="SmtpConfigEditForm__field"
          filled
          :label="$t('service.Username')"
          :placeholder="$t('service.loginUser')"
          name="smtp-user"
          :error-messages="getErrors('username')"
        />

        <AppTextField
          key="password"
          v-model="form.password"
          :label="$t('service.Password')"
          filled
          :type="previewPassword ? 'text': 'password'"
          name="password"
          :error-messages="getErrors('password')"
          margins-with-hidden-details="mb-4"
          margins-with-details="mb-2"
          class="SmtpConfigEditForm__field"
        >
          <template #append>
            <v-btn
              icon
              @click="previewPassword = !previewPassword"
            >
              <v-icon
                v-text="previewPassword ? '$eye-close' : '$eye'"
              />
            </v-btn>
          </template>
        </AppTextField>

        <AppSelect
          v-model="form.connectionSecurity"
          class="SmtpConfigEditForm__field"
          filled
          :items="SMTP_CONNECTION_SECURITY"
          :label="$t('service.ConnectionSecurity')"
          name="smtp-connection-security"
          margins-with-hidden-details="mb-0"
        />

        <AppTextField
          v-model="form.fromAddress"
          persistent-hint
          class="SmtpConfigEditForm__field"
          filled
          :label="$t('service.From')"
          placeholder="email@example.com"
          type="email"
          name="smtp-email"
          :hint="$t('service.NotificationsFromM')"
          :error-messages="getErrors('fromAddress')"
        />

        <div class="SmtpConfigEditForm__save-block mt-6">
          <v-btn
            :width="40"
            :max-width="40"
            :min-width="40"
            :disabled="!hasConfig"
            color="primary"
            outlined
            @click="deleteSmtpConfig"
          >
            <v-icon :color="hasConfig ? '#FF5252' : '#D3D6E2'">
              {{ hasConfig ? 'mdi-trash-can-outline' : 'mdi-delete-outline' }}
            </v-icon>
          </v-btn>

          <v-btn
            width="calc(100% - 64px)"
            color="primary"
            class="ml-6"
            :disabled="!useSmtp || saving || isSame"
            @click="saveSmtpConfig()"
          >
            {{ $t('service.Save') }}
          </v-btn>
        </div>

        <v-btn
          v-if="!showEmailForm"
          width="100%"
          text
          color="primary"
          :disabled="!useSmtp || saving"
          class="mt-6"
          @click="saveSmtpConfigAndSendEmail"
        >
          <v-icon v-text="'mdi-plus'" />
          {{ $t('service.TestEmailM') }}
        </v-btn>
      </fieldset>
    </v-form>

    <SmtpMailForm v-if="showEmailForm && smtpConfig && smtpConfig.enabled" />
  </div>
</template>

<script>
import { SMTP_MISSING, SMTP_CONNECTION_SECURITY, SMTP_NOT_LOADED } from '@/constants'
import { required, minValue, email } from 'vuelidate/lib/validators'
import * as R from 'ramda'
import i18n from '../i18n'

import SmtpMailForm from '@/components/SmtpMailForm'

const initForm = () => ({
  connectionSecurity: 'NONE',
  fromAddress: '',
  host: '',
  password: '',
  port: 25,
  username: '',
})

const validationErrors = {
  'fromAddress-required': i18n.t('service.validationErrors.FromAddressRequired'),
  'fromAddress-email': i18n.t('service.validationErrors.FromAddressEmail'),
  'host-required': i18n.t('service.validationErrors.HostRequired'),
  'port-required': i18n.t('service.validationErrors.PortRequired'),
  'port-positive': i18n.t('service.validationErrors.PortPositive'),
  'username-required': i18n.t('service.validationErrors.UsernameRequired'),
  'password-required': i18n.t('service.validationErrors.PasswordRequired'),
}

const formValidation = {
  fromAddress: { required, email },
  host: { required },
  port: { required, positive: minValue(1) },
  username: { required },
  password: { required },
}

export default {
  name: 'SmtpConfigEditForm',

  components: {
    SmtpMailForm,
  },

  data() {
    return {
      SMTP_CONNECTION_SECURITY,

      form: initForm(),

      saving: false,
      showEmailForm: false,
      useSmtp: false,
      previewPassword: false,
    }
  },

  computed: {
    smtpConfig() { return this.$store.state.service.smtpConfig },

    hasConfig() {
      return ![SMTP_MISSING, SMTP_NOT_LOADED].includes(this.smtpConfig)
    },

    isSame() {
      return R.equals(this.form, R.omit(['enabled'], this.smtpConfig))
    },
  },

  validations: {
    form: formValidation,
  },

  watch: {
    smtpConfig: {
      handler(smtpConfig) {
        if (smtpConfig) {
          this.form = { ...R.omit(['enabled'], smtpConfig) }
        } else {
          this.form = initForm()
          this.showEmailForm = false
        }
      },
      immediate: true,
    },

    hasConfig: {
      handler(hasConfig) {
        this.useSmtp = hasConfig && this.smtpConfig.enabled
      },
      immediate: true,
    },
  },

  methods: {
    async saveSmtpConfigAndSendEmail() {
      if (this.smtpConfig !== SMTP_MISSING && this.isSame) {
        this.showEmailForm = true
        return
      }

      await this.saveSmtpConfig(true)
    },

    async saveSmtpConfig(showEmail = false) {
      const { form, $store, smtpConfig } = this

      if (!smtpConfig) {
        this.$v.$touch() // highlight errors
        if (this.$v.$error) return
      } else {
        const config = R.omit(['enabled', 'password', 'connectionSecurity'], smtpConfig)
        Object.keys(config).forEach(key => {
          this.$v.form[key].$touch()
        })

        if (Object.keys(config).some(key => this.$v.form[key].$invalid)) return
      }

      this.saving = true

      try {
        await $store.dispatch(
          smtpConfig === SMTP_MISSING
            ? 'service/createSmtpConfig'
            : 'service/updateSmtpConfig',
          {
            smtpConfig: { ...form, enabled: this.useSmtp },
          },
        )
        if (showEmail) this.showEmailForm = true
        this.$v.form.$reset()
      } catch (e) {
        return console.error(e)
      } finally {
        this.saving = false
      }
    },

    getErrors(field) {
      const v = this.$v.form[field]

      if (!v.$dirty) return []
      return Object.entries(v)
        .filter(([k, _]) => !k.startsWith('$'))
        .filter(([_, v]) => !v)
        .map(([k, _]) =>
          validationErrors[`${field}-${k}`] ||
          `${this.$t('service.validationErrors.ValidationError')} ${field} ${k}`)
    },

    async deleteSmtpConfig() {
      const { $store } = this

      const agreed = await $store.dispatch('confirm/openDialog', {
        title: this.$t('service.DeleteSmtpConfigQ'),
        subtitle: this.$t('service.ActionImmediateM'),
        consentLabel: this.$t('service.Delete'),
        consentProps: { color: 'error', depressed: true },
      })

      if (agreed) {
        $store.dispatch('service/deleteSmtpConfig').then(() => {
          $store.commit('$snackbar/setMessage', {
            message: this.$t('service.SmtpDeletedSuccessM'),
          })
        })
      }
    },

    switchSmtp(event) {
      this.useSmtp = event
      if (this.smtpConfig) this.saveSmtpConfig()
    },
  },
}
</script>

<style scoped lang="sass">
  .SmtpConfigEditForm
    max-width: 520px

    &__switch
      margin: 28px 0
      display: flex
      align-items: center
      max-width: 520px

    &__fieldset
      border: none
      margin-top: 16px

    &__fieldset-legend
      border: none
      font-weight: 500
      font-size: 20px
      line-height: 32px
      letter-spacing: 0.015em
      margin-bottom: 16px

    &__field
      flex: 0 0 calc(50% - 24px)

    &__save-block
      display: flex
      align-items: center
</style>
