<template>
  <div class="SmtpConfig">
    <AppBarLayout>
      <template #header>
        {{ $t('service.SimpleMailTransferProtocol') }}
      </template>
    </AppBarLayout>

    <div
      v-if="isLoaded"
      class="SmtpConfig__page"
    >
      <SmtpConfigEditForm />
    </div>
  </div>
</template>

<script>
import AppBarLayout from '../layouts/AppBarLayout'
import SmtpConfigEditForm from '@/components/SmtpConfigEditForm'

export default {
  name: 'SmtpConfig',

  components: {
    AppBarLayout,
    SmtpConfigEditForm,
  },

  metaInfo() {
    return {
      title: this.$store.getters.title(this.$t('service.SmtpConfig')),
    }
  },

  data() {
    return {
      isLoaded: false,
    }
  },

  async created() {
    await this.$store.dispatch('service/getSmtpConfig')
    this.isLoaded = true
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.SmtpConfig
  padding: 64px
  display: flex
  justify-content: center
  background: #F5F5F9
  height: 100%

  &__page
    flex: 0 1 1152px
</style>
