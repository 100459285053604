<template>
  <v-form
    ref="form"
    class="SmtpMailForm mt-9"
  >
    <fieldset class="SmtpMailForm__fieldset">
      <legend
        class="SmtpMailForm__fieldset-legend"
        v-text="$t('service.TestEmailM')"
      />

      <AppTextField
        v-model="form.toAddress"
        class="SmtpMailForm__field"
        persistent-hint
        filled
        :label="$t('service.To')"
        placeholder="email@example.com"
        :hint="$t('service.TestEmailSentToM')"
        name="mail-to-address"
        :error-messages="getErrors('toAddress')"
      />

      <AppTextField
        v-model="form.subject"
        class="SmtpMailForm__field"
        filled
        :label="$t('service.Subject')"
        :placeholder="$t('service.Subject')"
        name="mail-subject"
        :error-messages="getErrors('subject')"
      />

      <AppTextarea
        v-model="form.message"
        class="SmtpMailForm__field"
        filled
        :label="$t('service.Message')"
        :placeholder="$t('service.Message')"
        name="mail-message"
        :error-messages="getErrors('message')"
      />

      <v-btn
        width="100%"
        color="primary"
        class="mt-6"
        :disabled="!form.toAddress || !form.subject || !form.message || saving"
        :loading="saving"
        @click="sendEmail"
      >
        {{ btnText }}
      </v-btn>
    </fieldset>
  </v-form>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'
import i18n from '../i18n'

const initForm = () => ({
  toAddress: '',
  subject: '',
  message: '',
})

const validationErrors = {
  'toAddress-required': i18n.t('service.validationErrors.ToAddressRequired'),
  'toAddress-email': i18n.t('service.validationErrors.ToAddressEmail'),
  'subject-required': i18n.t('service.validationErrors.SubjectRequired'),
  'message-required': i18n.t('service.validationErrors.MessageRequired'),
}

const formValidation = {
  toAddress: { required, email },
  subject: { required },
  message: { required },
}

export default {
  name: 'SmtpMailForm',

  data() {
    return {
      form: initForm(),

      saving: false,
      btnText: this.$t('service.SendTestEmail'),
    }
  },

  validations: {
    form: formValidation,
  },

  methods: {
    async sendEmail() {
      const { form: mail, $store } = this

      this.$v.$touch() // highlight errors
      if (this.$v.$error) return

      this.saving = true
      try {
        await $store.dispatch('service/sendTestEmail', { mail })
        this.$v.form.$reset()

        $store.commit('$snackbar/setMessage', {
          message: this.$t('service.TestEmailSentToSuccessM', { email: mail.toAddress }),
        })

        this.btnText = this.$t('service.ResendTestEmail')
      } catch (e) {
        return console.error(e)
      } finally {
        this.saving = false
      }
    },

    getErrors(field) {
      const v = this.$v.form[field]

      if (!v.$dirty) return []
      return Object.entries(v)
        .filter(([k, _]) => !k.startsWith('$'))
        .filter(([_, v]) => !v)
        .map(([k, _]) =>
          validationErrors[`${field}-${k}`] ||
          `${this.$t('service.validationErrors.ValidationError')} ${field} ${k}`)
    },
  },
}
</script>

<style scoped lang="sass">
.SmtpMailForm
  max-width: 520px

  &__fieldset
    border: none
    margin-top: 16px

  &__fieldset-legend
    border: none
    font-weight: 500
    font-size: 20px
    line-height: 32px
    letter-spacing: 0.015em
    margin-bottom: 16px

  &__field
    flex: 0 0 calc(50% - 24px)
</style>
